.videoBox[data-v-10f5a9fc] {
  width: 100%;
}
.videoTopBox[data-v-10f5a9fc] {
  background-color: #303133;
  position: relative;
}
.timeBox[data-v-10f5a9fc] {
  position: absolute;
  right: 3px;
  bottom: 0;
  color: #FFFFFF;
  font-size: 20px;
}
.videoBtn[data-v-10f5a9fc] {
  position: absolute;
  bottom: 3px;
  left: 3px;
}
[data-v-10f5a9fc] .videoBtn i {
  font-size: 22px;
}
.video[data-v-10f5a9fc] {
  width: 100%;
  height: 50vh;
}
.videoBottomBox[data-v-10f5a9fc] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.name[data-v-10f5a9fc] {
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 24px;
  margin: 6px 0;
}
.videoLittleBox[data-v-10f5a9fc] {
  width: calc(25% - 24px);
  margin: 0 12px;
  height: calc(100% - 6px);
  cursor: pointer;
  border: 1px solid transparent;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.littleVideo[data-v-10f5a9fc] {
  width: 100%;
  height: calc(100% - 27px);
}
.videoLittleBox img[data-v-10f5a9fc] {
  width: 100%;
  height: calc(100% - 27px);
}
.moveButton[data-v-10f5a9fc] {
  height: 32px;
  margin: calc(10vh - 16px) 0;
}
.videoBigSwiper[data-v-10f5a9fc] {
  width: calc(100% - 64px);
  height: 20vh;
  overflow: hidden;
}
.videoSwiper[data-v-10f5a9fc] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.learnName[data-v-10f5a9fc] {
  width: 100%;
  text-align: center;
  color: #2979FF;
  font-size: 16px;
}